import React, { useState } from 'react';
import './packageDimensionForm.scss';
import { FaPlus } from 'react-icons/fa';
import { IoCloseOutline, IoTrashOutline } from 'react-icons/io5';

const PackageDimensionsForm = ({
    packageDimensions,
    selectedPackage,
    handlePackageChange,
    handlePackageInputChange,
    handlePackageUpdate,
    handleAddNewPackage,
    handleDeletePackage,
    showNewPackageForm,
    setShowNewPackageForm,
    newPackage,
    setNewPackage,

}) => {


    const toggleNewPackageForm = () => {
        setShowNewPackageForm(prev => !prev);
    };

    const handleDelete = () => {
        if (selectedPackage) {
            handleDeletePackage(selectedPackage._id);
        }
    };
    const handleNewPackageInputChange = (e) => {
        const { name, value } = e.target;
        setNewPackage(prev => ({ ...prev, [name]: value }));
    };

    return (
        <div className="package-dimensions-form">
            <div className="package-dimensions-form__header">
                <h3 className="package-dimensions-form__label">Package Dimensions</h3>
                <FaPlus className="package-dimensions-form__add-icon" onClick={toggleNewPackageForm} />
            </div>

            <select
                className="package-dimensions-form__select"
                onChange={handlePackageChange}
                value={selectedPackage ? selectedPackage._id : ''}
            >
                <option value="" disabled>Select a package</option>
                {packageDimensions.map(pkg => (
                    <option key={pkg._id} value={pkg._id}>
                        {pkg.name}
                    </option>
                ))}
            </select>

            {selectedPackage && (
                <div className="package-dimensions-form__details">
                    <div className="package-dimensions-form__group">
                        <h3 className="package-dimensions-form__label">Length</h3>
                        <input
                            className="package-dimensions-form__input"
                            type="number"
                            name="length"
                            value={selectedPackage.length || ''}
                            onChange={handlePackageInputChange}
                        />
                    </div>
                    <div className="package-dimensions-form__group">
                        <h3 className="package-dimensions-form__label">Breadth</h3>
                        <input
                            className="package-dimensions-form__input"
                            type="number"
                            name="breadth"
                            value={selectedPackage.breadth || ''}
                            onChange={handlePackageInputChange}
                        />
                    </div>
                    <div className="package-dimensions-form__group">
                        <h3 className="package-dimensions-form__label">Height</h3>
                        <input
                            className="package-dimensions-form__input"
                            type="number"
                            name="height"
                            value={selectedPackage.height || ''}
                            onChange={handlePackageInputChange}
                        />
                    </div>

                    <button className="package-dimensions-form__delete-button" onClick={handleDelete}>
                        <IoTrashOutline />
                    </button>
                </div>
            )}
            {selectedPackage && (
                <button className="package-dimensions-form__update-button" onClick={handlePackageUpdate}>
                    Update Package
                </button>
            )}

            {/* The form that appears in the overlay */}
            <div className={`package-dimensions-form__overlay ${showNewPackageForm ? 'package-dimensions-form__overlay--active' : ''}`}>
                <div className="new-package-form">
                    <h3>Add New Package Dimensions</h3>
                    <IoCloseOutline className="close-icon" onClick={toggleNewPackageForm} />
                    <div className="package-dimensions-form__group">
                        <input
                            className="package-dimensions-form__input"
                            type="text"
                            placeholder="Package Name"
                            name="name"
                            onChange={handleNewPackageInputChange}
                        />
                    </div>
                    <div className="package-dimensions-form__group">
                        <input
                            className="package-dimensions-form__input"
                            type="number"
                            placeholder="Length"
                            name="length"
                            onChange={handleNewPackageInputChange}
                        />
                    </div>
                    <div className="package-dimensions-form__group">
                        <input
                            className="package-dimensions-form__input"
                            type="number"
                            placeholder="Breadth"
                            name="breadth"
                            onChange={handleNewPackageInputChange}
                        />
                    </div>
                    <div className="package-dimensions-form__group">
                        <input
                            className="package-dimensions-form__input"
                            type="number"
                            placeholder="Height"
                            name="height"
                            onChange={handleNewPackageInputChange}
                        />
                    </div>
                    <button className="package-dimensions-form__update-button" onClick={handleAddNewPackage}>
                        Add Package
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PackageDimensionsForm;
