import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import './App.css';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Inactive from './Context/SidebarInactive';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from 'jwt-decode';
import { decodeToken } from "./redux/apiCalls";
import { logout } from "./redux/sellerRedux";
import HomePage from "./pages/HomePage/HomePage";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/ForgotPassword/ChangePassword";
import SubscriptionPlans from "./pages/SubscriptionPlans/SubscriptionPlans";
import PlanPage from "./pages/SubscriptionPlans/SubscriptionPlans";
import StoreDetails from "./pages/Settings/StoreDetails/StoreDetails";
import OfflineOverlay from "./components/OfflineOverlay/OfflineOverlay";
import Payment from "./components/store/Cart/Payment/Payment";
import Layout from "./components/Layout/Layout";
import Order from "./components/Order/Order";
import PaymentSettingsForm from "./pages/Settings/PaymentSettingsForm/PaymentSettingsForm";
import TransactionReport from "./components/Reports/TransactionReport";
import AboutUs from "./pages/HomePage/AboutUs/AboutUs";
import ContactUs from "./pages/HomePage/ContactUs/ContactUs";
import Pricing from "./pages/HomePage/Pricing/Pricing";
import PrivacyPolicy from "./pages/HomePage/Policies/PrivacyPolicy";
import TermsConditions from "./pages/HomePage/Policies/TermsConditions";
import CancellationRefundPolicy from "./pages/HomePage/Policies/CancellationRefundPolicy";
import ShippingAndDelivery from "./pages/HomePage/Policies/ShippingAndDelivery";
import OrderReport from "./components/Reports/OrderReport";
// import Orders from "./components/Orders/Orders";

const Dashboard = lazy(() => import('./pages/sellerDashboard/SellerDashboard'));
const Home = lazy(() => import('./pages/home/Home'));
const Login = lazy(() => import('./pages/login/Login'));
const Register = lazy(() => import('./pages/SellerRegistration/SellerRegistration'));
// const Orders = lazy(() => import('./pages/Order/Orders'));
const Orders = lazy(() => import('./components/Orders/Orders'))
const SingleOrder = lazy(() => import('./components/Order/Order'));

// import Order from '../../components/Order/Order'
const Users = lazy(() => import('./pages/User/Users'));
const Store = lazy(() => import('./pages/Store/Store'));
// const Products = lazy(() => import('./pages/Product/Products'));
const Products = lazy(() => import('./components/Products/Products'));
const SingleProduct = lazy(() => import('./components/Product/Product'));
// const Customers = lazy(() => import('./pages/Customer/Customers'));
const Customers = lazy(() => import('./components/Customers/Customers'));
const SingleCustomer = lazy(() => import('./components/Customer/Customer'));
const Shipment = lazy(() => import('./components/Shipment/Shipment'));
const OnlineStore = lazy(() => import('./components/OnlineStore/OnlineStore'));
const ProductPage = lazy(() => import('./pages/Store/ProductPage'));
const CheckoutPage = lazy(() => import('./pages/Store/CheckoutPage'));

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.seller?.accessToken);
  useEffect(() => {
    if (accessToken) {
      const decodedToken = decodeToken(accessToken);

      if (decodedToken) {
        console.log("SUCCESS");
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        console.log("false");
        dispatch(logout());
        navigate('/login');
      }

    } else {
      navigate('/login');
    }
    setLoading(false);
  }, [accessToken, navigate, dispatch]);

  if (loading) {
    return (
      <Backdrop
        open
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

function App() {
  return (
    <div className="App">
      <OfflineOverlay />
      <Inactive>
        <Router>
          <Suspense fallback={
            <Backdrop
              open
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path='/reset-password/:token' element={<ChangePassword />} />
              <Route path="/subscriptionplans" element={<SubscriptionPlans />} />
              <Route path="/paynow" element={<Payment />} />

              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/terms&conditions" element={<TermsConditions />} />
              <Route path="/cancellationRefundPolicy" element={<CancellationRefundPolicy />} />
              <Route path="/shippinganddelivery" element={<ShippingAndDelivery />} />

              {/* Protected Routes */}
              <Route path="/" element={<ProtectedRoute />}>
                <Route element={<Layout />}>

                  {/* Move more specific routes here */}
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/settings/paymentSettings" element={<PaymentSettingsForm />} />
                  <Route path="/settings/orders" element={<TransactionReport />} />

                  <Route path="/report/transactions" element={<TransactionReport />} />
                  <Route path="/report/orders" element={<OrderReport />} />

                  <Route path="/order/:orderID" element={<SingleOrder />} />

                  <Route path="/products" element={<Products />} />
                  <Route path="/product/:productID" element={<SingleProduct />} />
                  <Route path="/product/new" element={<SingleProduct />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/customer/:customerID" element={<SingleCustomer />} />
                  <Route path="/shipment" element={<Shipment />} />
                  <Route path="/store" element={<OnlineStore />} />
                  <Route path="/plans" element={<PlanPage />} />
                  <Route path="/settings/storedetails" element={<StoreDetails />} />
                </Route>
              </Route>

              {/* Dynamic routes should come last */}
              <Route path="/:sellerID" element={<Store />} />
              <Route path="/:sellerID/:productID" element={<ProductPage />} />
              <Route path="/:sellerID/checkout" element={<CheckoutPage />} />
              <Route path="/:sellerID/checkout/:productIDs" element={<CheckoutPage />} />
            </Routes>

          </Suspense>
        </Router>
      </Inactive>
    </div>
  );
}

export default App;
